import _ from 'underscore';
import { CollectionView } from 'pages/views';
import { OfferItemCard } from 'mod/realties/offers/views';
import { mix, comparator } from 'bbmn-utils';
import { initSelectorMixin } from 'bbmn-components';
import { collectionViewFiltererMixin } from 'components/filterer';
import { viewWithPaginatorMixin } from 'components/paginator';

import { View } from 'base';

const Founded = View.extend({
	renderOnModelChange: true,
	className: 'entries-founded',
	tagName: 'header',
	template: _.template('нашлось: <%= _m.get("founded") %>'),
});


export default mix(CollectionView).with(initSelectorMixin).extend({
	renderAllCustoms: true,
	isEmpty(){
		return !this.collection.length;
	},
	className: 'offers-cards',
	childView: OfferItemCard,
	childViewOptions(){
		
		let listModel = this.model;
		let collection = this.getCollection();
		let options = {
			//mainAction: 'open:card',
			modelViewOptions:{
				//mainAction: 'open:card',
				removeButton: true,
				onRemoveClick(){
					listModel.removeItems([this.model.id], () => {
						collection.remove(this.model);
						collection.manager.changeFounded(-1);
					});
				},
			}
		};
		return options;	
	},
	customs:[ v => new Founded({ model: v.collection.manager })],
});
