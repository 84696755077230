
import _ from 'underscore';
//import { enums } from 'bbmn-utils';
import { OfferLists } from '../offer-lists/models'
import { OfferListItemView } from '../offer-lists/views'
import busApp from 'bus/app';

export const offerFiltersOverrides = {
	offnotinlistsatall: {
		multiple: false,
		sourceValues: { true: 'нет ни в одном списке', false: 'есть хотябы в одном' },
		valueType: 'enum',
		control: 'select:inline',
	},		
	offnotinlist: {
		multiple: false,
		sourceValues: () => new OfferLists(),

		schemaEditOptions: {
			childView: OfferListItemView,
			extractValue: model => model.id
		},
		schemaDisplayOptions: {
			transform: v => v && busApp.request('offerList:name', v)
		},
	},
	offinlist: {
		multiple: false,
		sourceValues: () => new OfferLists(),

		schemaEditOptions: {
			childView: OfferListItemView,
			extractValue: model => model.id
		},
		schemaDisplayOptions: {
			transform: v => v && busApp.request('offerList:name', v)
		},
	},		

};