import '../api-config';
import { ManagedCollection, Model } from 'base';
import { CardModel, ActionModel } from 'mod/model-mixins';
import { ModelSchemas } from 'bbmn-components';
import { paths } from 'helpers';
import { createSchemaNotifies } from 'components/notifier';
import { action, ActionStore } from 'components/actions';
//import { modals } from 'bbmn-components';
//import { EditModel } from 'bbmn-controls';
import ModelStore from 'backbone.store';
import editor from 'components/editor';
import { enums } from 'bbmn-utils';
import busApp from 'bus/app';
import { busViews } from 'bus';

//import { RuleView } from '../feeds/views/feed-packs';
//import { enums } from 'bbmn-utils';

paths.set('api', 'realties:offer-lists', 'offerlists', { relative: true });
paths.set('url', 'realties:offer-lists', 'offer-lists', { relative: true });

const BaseOfferList = CardModel.extend({
	urlRoot: paths.api('realties:offer-lists'),
	cardUrlRoot: paths.url('realties:offer-lists'),

	addItems(ids){
		if(!ids || !ids.length){
			return;
		}
		return this.save({ ids }, {
			method:'post',
			url: this.getItemsUrl()
		});
	},
	getItemsUrl(){
		return this.url() + '/items';
	},
	removeItems(ids, onSuccess){
		let removeModel = new Model();
		return removeModel.save({ ids }, {
			method:'delete',
			url: this.getItemsUrl(),
		}).then(() => {
			if(_.isFunction(onSuccess))
				return onSuccess(ids);
		});
	}

});
ModelSchemas.initialize(BaseOfferList, {
	id: {
		value:{
			control:'select:api',
			sourceValues: () => new OfferLists()
		},
		edit:{
			filtered: true,
			childView: () => busViews.getClass('OfferListItemView')
		},		
		display:{
			label:'список',
			transform(){
				return this && this.display('name');
			},
			ifEmpty: '&ndash;'
		}
	},	
	name:{
		validation: {
			required:true
		},
		display:{
			label:'Название'
		}
	},
	type: {
		value:{
			type:'enum',
			control:'select:inline',
			sourceValues: () => enums.get('realtyListTypes')
		},
		display:{
			label:'Тип списка'
		}
	}
});
export const OfferList = ModelStore(BaseOfferList);

export const OfferLists = ManagedCollection.extend({
	model: OfferList,
	url: paths.api('realties:offer-lists'),
});
const alllists = new OfferLists();
busApp.on('user:auth', () => {
	alllists.fetchIfNot();
});
busApp.reply('offerList:name', (id) => alllists.get(id).get('name'));

