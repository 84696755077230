import _ from 'underscore';
import { enums } from 'bbmn-utils';
import { RealtyLists } from '../realty-lists/models'
import { RealtyListItemView } from '../realty-lists/views'
import busApp from 'bus/app';

export const realtyFiltersOverrides = {
	notinlistsatall: {
		multiple: false,
		sourceValues: { true: 'нет ни в одном списке', false: 'есть хотябы в одном' },
		valueType: 'enum',
		control: 'select:inline',
	},		
	notinlist: {
		multiple: false,
		sourceValues: () => new RealtyLists(),

		schemaEditOptions: {
			childView: RealtyListItemView,
			extractValue: model => model.id
		},
		schemaDisplayOptions: {
			transform: v => v && busApp.request('realtyList:name', v)
		},
	},
	inlist: {
		multiple: false,
		sourceValues: () => new RealtyLists(),

		schemaEditOptions: {
			childView: RealtyListItemView,
			extractValue: model => model.id
		},
		schemaDisplayOptions: {
			transform: v => v && busApp.request('realtyList:name', v)
		},
	},		
	RealtyBuildingClass:{
		name: 'класс',
		valueType: 'enum',
		sourceValues: enums.get('realtyClassesFlags'),
		control: 'select:inline',
		multiple: true,
	}
};