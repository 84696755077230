import { CollectionView } from 'base';
import { ListItemView, ModelCoreView } from 'components/views';
import ApiSelect from 'controls/select-api';
import { defineControl, SelectControl } from 'bbmn-controls';
import { isCollectionClass } from 'bbmn-utils';

import { UiBlockView } from 'components/views';
import { switcherInverse } from 'controls/boolean-switch';
import { Offers, OfferLists } from './models';
import { busViews } from 'bus';

const BaseOfferItemView = ModelCoreView.extend({
	topText: m => m.display('prefix'),
	text: m => m.get('name'),
});

export const SelectOfferItemView = ListItemView.extend({
	className:'offer-item',
	optionsButton: true,
	modelView: BaseOfferItemView,
});

// busViews.class('RealtyListItemView', RealtyListItemView);

export const OffersView = CollectionView.extend({
	childView: SelectOfferItemView
});


export const ApiSelectOffers = ApiSelect.extend({
	getSource() {
		return new Offers();
	}
});
defineControl('select:api:offers', ApiSelectOffers);
defineControl('select:api:offer', ApiSelectOffers);
