import _ from 'underscore';
import { CollectionView } from 'base';
import { ListItemView, ModelCoreView } from 'components/views';

import { UiBlockView } from 'components/views';
import { switcherInverse } from 'controls/boolean-switch';

import { modals, ModelSchemas } from 'bbmn-components';
import { DisplayModelSchemaView } from 'components/display-schema';

import './feed-packs.less';


/*	PROPERTY */
const BasePropertyItemView = ModelCoreView.extend({
	text: m => m.display('path'),
});

export const PropertyItemView = ListItemView.extend({
	//optionsButton: true,
	renderOnModelChange: true,
	editButton: true,
	removeButton: true,
	modelView: BasePropertyItemView,
	rightItems:[
		m => ({
			topText: m.display('compareRule'),
			text: m.display('value')
		})
		//m => m.display('compareRule'),
		//m => m.display('value')
	],
	onRemoveClick(event){
		event.stopPropagation();
		//let rule = this.model.collection.parent;
		this.model.collection.remove(this.model);
	},
	onEditClick(){
		let model = this.model;
		modals.show({
			header:'изменение свойства',
			promiseBar: true,
			onResolve(content){
				model.set(content.value);
			},
			content: DisplayModelSchemaView,
			contentOptions:{
				value: model.toJSON(),
				schema: ModelSchemas.get(model),
				mainPropertyAction:'trigger:edit:click'
			}
		});
	}
});

export const PropertiesItemsView = CollectionView.extend({
	childView: PropertyItemView,
	initialize(){
		console.log('this properties', this.collection.toJSON());
	}
});

/*	RULE */

// export const EditRulePropertyView = CollectionView.extend({

// });

const BaseRuleItemView = ModelCoreView.extend({
	topText: m => m.display('disabled'),
	text: m => m.get('name'),
});

export const RuleItemView = ListItemView.extend({
	//optionsButton: true,
	cssClassModifiers:[
		'feed-common-item-list',
		m => m.isDisabled() ? 'disabled' : '',
	],	
	leftItems: [
		m => switcherInverse(m, 'disabled'),
	],	
	modelView: BaseRuleItemView,
	removeButton: true,
	onRemoveClick(event){
		event.stopPropagation();		
		//let pack = this.model.collection.parent;
		this.model.collection.remove(this.model);
		//pack.patchRules();
	}
});

export const RulesItemsView = CollectionView.extend({
	childView: RuleItemView,
});

export const RuleView = CollectionView.extend({
	renderAllCustoms: true,
	getCustoms(){
		return [
			this.getPropertiesView(),
			this.getNestedRulesView(),
		];
	},
	getPropertiesView(){
		let model = this.model;
		let props = this.model.get('properties');
		//console.log('this props UNfixed ', JSON.stringify(props));
		let wasFixed = false;
		_.each(props, (prop, ind) => {
			if (prop.id == null) {
				prop.id = ind + 1;
				wasFixed = true;
			}
		});
		if(wasFixed){
			this.model.set('properties', props, { silent: true });
		}		
		return new UiBlockView({
			model: this.model,
			header: 'свойства',
			content: PropertiesItemsView,
			contentOptions:{
				collection: model.entity('properties'),
				childViewOptions:{
					mainAction:'view:property'
				}
			},
			actions:['add:property'],
		});
	},
	getNestedRulesView(){

	},
});


/*	FEED PACK */

export const BaseFeedPackItemView = ModelCoreView.extend({
	text: m => m.get('name'),
	topText: m => m.display('disabled'),
	bottomText: m => m.getRulesNames().join(', ') || 'правила не установлены',
});


const FeedPacksItemView = ListItemView.extend({
	className: 'feed-pack',
	//optionsButton: true,
	leftItems: [
		m => switcherInverse(m, 'disabled', 'save'),
	],
	modelView: BaseFeedPackItemView,
	cssClassModifiers:[
		'feed-common-item-list',
		m => m.isDisabled() ? 'disabled' : '',
		m => m.hasNoRules() ? 'no-rules' : ''
	]
});


export const FeedPacksListView = CollectionView.extend({
	className: 'feed-packs',
	childView: FeedPacksItemView
});
