import BasePage from 'pages/auth';
import { BackendOffers } from 'mod/realties/offers/models';
import { getFilters } from 'mod/realties/offers/filters';
import Content from './content';
import { Selector } from 'bbmn-components';
import { Filterer } from 'components/filterer';
import offersActions from 'mod/realties/offers/mass-actions';
//import RealtyPage from './realty';

export default BasePage.extend({
	id:'realties-offers',
	routes: 'offers',
	label: 'Каталог предложений',
	//children: [RealtyPage],
	Content,
	LayoutOptions: p => ({
		selector: p.selector,
		filterer: p.filterer,
		fetchNextEdge: 'bottom',
		scrollHandlingEnabled: true,		
	}),
	initialize(){
		this.filterer = new Filterer({ formatDataForQueryString: false });

		this.collection = new BackendOffers();
		this.collection.setFilterer(this.filterer);

		this.selector = new Selector({ 
			source: this.collection, 
			multiple: true,
		});
		this.selector.actions = offersActions(this.selector, { entriesCollection: this.collection});
		this.selector.actionArguments = [this.collection];
		this.addUrlChanger(this.filterer);

	},
	onBeforeStart(p,ac){
		
		console.log('BEFORE START BEGINS');
		if (this.filterer._inited) {
			console.log('not in first time, so skip');
			return;
		}
		this.filterer.setFilters(getFilters(), { qs: ac.qs });
		this.filterer._inited = true;
		console.log('Filters settled', this.filterer);
		if (!this.filterer.hasValues() && !this.collection.isFetching()) {
			console.log('filterer is empty, and there is no fetch on coll, so doing fetch');
			this.collection.query();
		}
		// if (!this.collection.isFetching()) {
		// 	console.log('not fetched yet! doing fetch');
		// 	this.collection.query();
		// }

	},
	onBeforeStop(){
		console.log('BEFORE STOP!');
		this.stopListening();
	},
	//children:[RealtyLists, OffersLists],
});
