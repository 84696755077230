import _ from 'underscore';
import { View, CollectionView, Collection, Model } from 'base';
import { buildViewByKey } from 'bbmn-utils';
import { SelectorState } from 'components/selector';
import { FiltersView } from '../../components/filterer';
import { LinkView } from 'components/views';
import smartOpen from 'helpers/open-url';

const TabLinkView = LinkView.extend({
	text: m => m.get('label'),
	href: m => m.get('url'),
});

const PageMenu = CollectionView.extend({
	className: 'aside-page-menu',
	initialize(options){
		this.page = options.page;
	},	
	renderCollection: false,
	renderAllCustoms: true,
	customs:[
		v => v.getParentLink(),
		//v => v.getLinksView('children'),
		v => v.getLinksView('siblings', { addRoot: true }),
	],
	getParentLink(){
		let hash = this.page.getHashes();
		let modelHash = hash.isParentHash ? hash.this : hash.parent;
		if (!modelHash) return;
		return new TabLinkView({ className: 'page-link alone go-up', model: new Model(modelHash)});
	},
	getLinksView(name, { addRoot } = {}){
		let hash = this.page.getHashes();
		let models = hash[name];
		if (addRoot) {
			models.push(hash.root);
		}
		let collection = new Collection(models);
		if(!collection.length) return;
		let header = ({ children: 'Выберите раздел: ', siblings: 'Смотрите также: '})[name];

		return new CollectionView({
			renderAllCustoms: true,
			className: 'page-links ' + name,
			tagName: 'nav',
			collection,
			childView: TabLinkView,
			viewComparator(v1,v2){
				return (v1.model.get('order') || 0) - (v2.model.get('order') || 0);
			},
			customs:[() => name == 'siblings' && new View({ tagName: 'header', template: () => header })]
		});		
	}
});



const Tab = Model.extend({
	show(){
		let view = buildViewByKey(this.attributes, 'view');
		this.trigger('show',this,view);
	},
	setActive(arg){
		if(arg == null) arg = true;
		this.set('active', arg);
	},
	isActive(){
		return this.get('active') === true;
	},
	activeState(){
		return this.isActive() ? 'active' : '';
	},	
});
const Tabs = Collection.extend({
	
	initialize(){
		this.on('show',this.handleShow);
	},
	model: Tab,
	handleShow(model){
		if (this.active) {
			this.active.setActive(false);
		}
		this.active = model;
		this.active.setActive();
	}
});

const TabView = View.extend({
	className: 'tab-trigger',
	cssClassModifiers:[ m => m.id, m => m.activeState() ],
	template: _.template('<i></i><small><%= text %></small>'),
	events:{
		'click'(){
			this.model.show();
		}
	}
});

const TabsView = CollectionView.extend({
	emptyView: false,
	className: 'tabs-triggers',
	childView: TabView,
	collectionEvents:{
		show(model,view){
			this.triggerMethod('show:tab:content', view);
		}
	}
});

const PageAction = View.extend({
	className: 'page-action',
	tagName: 'button',
	template: _.template('<span><%= label %></span>'),
	events: {
		click(e) {
			const action = this.model.get('action');
			const type = typeof action
			const page = this.getOption('page');
			if (type === 'function') {
				return action(e, page);
			} else if (type === 'string') {
				return this.triggerMethod(action);
			}
		}
	},
	// initialize() {
	// 	console.log('ACTION', this.model.attributes);
	// }
});

const PageActions = CollectionView.extend({
	className: 'page-actions',
	childView: PageAction,
	childViewOptions() {
		return {
			page: this.getOption('page'),
			asideViewOptions: this.getOption('asideViewOptions')
		}
	},
	initialize() {
		const models = this.getOption('actions');
		this.collection = new Collection(models);
	}
});

const PageSubdivision = View.extend({
	className: 'page-subdivision',
	cssClassModifiers: [ m => m.get('current') ? 'current' : ''],
	// tagName: 'button',
	template: _.template('<span><%= label %></span>'),
	events: {
		click(e) {
			const url = this.getUrl();
			console.warn('url::', url);
			return smartOpen(url, e);
		}
	},
	getUrl() {
		const route = this.model.get('route');
		const isRoot = route === 'root';
		const rootUrl = '/' + this.model.collection.pageRootUrl;
		if (isRoot) { return rootUrl; }
		return rootUrl + '/' + route;
	}
});

const PageSubdivisions = CollectionView.extend({
	className: 'page-subdivisions',
	childView: PageSubdivision,
	childViewOptions() {
		return {
			page: this.getOption('page')
		}
	},
	initialize() {
		const page = this.getOption('page');
		const divs = this.getOption('subdivisions');
		const currentId = page.getOption('subdivisionId');
		const subdivisionRootUrl = this.getOption('subdivisionRootUrl');

		const models = _.map(divs, (label, route) => ({ label, route, current: currentId === route }));
		this.collection = new Collection(models);
		this.collection.pageRootUrl = subdivisionRootUrl;

	}
});


export default View.extend({
	className:'aside-content',
	template: _.template('<nav></nav><section></section>'),
	regions:{
		'tabs':'nav',
		'content':'section'
	},
	getTabsModels(page, ac){
		let tabs = [];
		if(page){
			if(page.selector){
				tabs.unshift({
					id:'selector',
					text: 'отобранное',
					view: SelectorState,
					viewOptions: {
						selector: page.selector,
						actionArguments: page.getOption('selectorActionArguments') || page.selector.actionArguments
					}
				});
			}
			if(page.filterer){
				tabs.unshift({
					id:'filterer',
					text: 'фильтры',
					view: FiltersView,
					viewOptions: {
						filterer: page.filterer,
						collection: page.filterer.entity('items'),
						viewFilter(v){
							if(!this.filterer || !v.model || this.filterer.isBackend()){
								return true;
							}
							return v.model.isCompareAllowed();
						}
					}
				});
			}
			if(page.asideTabs){
				tabs.unshift(...page.asideTabs);
			}
		}

		page.Content && tabs.unshift({ 
			id: 'top-menu',
			text: 'меню',
			view: PageMenu,
			viewOptions: {
				page,
			}			
		});
		_.each(tabs, tab => tab.page = page);

		page.actions && tabs.unshift({
			id: 'actions',
			text: 'действия',
			view: PageActions,
			viewOptions: {
				page,
				actions: page.actions,
				//asideViewOptions: Object.assign({}, this.options)
			}	
		})


		const subdivs = this._getSubDivisionTab(page, ac);
		if (subdivs) {
			tabs.unshift(subdivs);
		}
		//console.log('TABS', page);
		return tabs;
	},
	_getSubDivisionTab(page, ac) {
		const sdId = page.getOption('subdivisionId');
		if (!sdId) { return; }
		const root = sdId === 'root' ? page : page.getParent();
		const rootHash = root.getHash(ac.args);
		//console.warn('root hash', rootHash);
		const subdivisions = root.getOption('subdivisions', true);
		const subdivisionRootUrl = rootHash.url;

		return {
			id: 'subdivs',
			text: 'подразделы',
			view: PageSubdivisions,
			viewOptions: {
				page,
				subdivisionRootUrl,
				subdivisions,
				sdId,
			}	
		}

	},
	showTabs(page, ac){
		let tabs = new Tabs(this.getTabsModels(page, ac));
		//console.log('~~ tabs', tabs.toJSON());
		// this.tabs || (this.tabs = new Tabs());
		// this.tabs.reset(tabs);
		// this.tabsView = new TabsView({ collection: this.tabs });
		// this.showChildView('tabs', this.tabsView);
		// let def = this.tabs.findWhere({isDefault: true});
		// if(!def) {
		// 	def = this.tabs.first();
		// }
		// if(def){
		// 	def.show(page);
		// }
		let tabsView = new TabsView({ collection: tabs });
		this.showChildView('tabs', tabsView);
		let def = tabs.findWhere({isDefault: true}) || tabs.first();
		def && def.show(page);
	},
	showContent(view){
		if (view) {
			this.showChildView('content', view);
		} else {
			this.getRegion('content').empty();
		}
	},
	showPage(page, ac){
		if (!page.noAside) {
			this.showTabs(page, ac);
		} else {
			this.render();
		}
	},
	childViewEvents:{
		'show:tab:content':'showContent'
	}
});
