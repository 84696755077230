import _ from 'underscore';
import { CollectionView } from 'pages/views';
import { UiBlockView } from 'components/views';
import DisplaySchema from 'components/display-schema';
import { RulesItemsView } from 'mod/export/feeds/views';
export default CollectionView.extend({
	initialize(){

	},
	renderAllCustoms: true,
	modelEvents:{
		'change:rules'(instance, changes, options = {}){
			if (options.changeInitiator) {
				this.model.save(null, { attrs: this.model.toJSON(), method: 'patch', wait: false });
			}
		}
	},
	getCustoms(){
		return [
			this.getModelView(),
			this.getPropertiesRules(),
			//this.getPacksView(),
		];
	},
	getPropertiesRules(){
		let rules = this.model.get('rules');
		//console.log('this rules UNfixed ', JSON.stringify(rules));
		let wasFixed = false;
		_.each(rules, (rule, ind) => {
			if (rule.id == null) {
				rule.id = ind + 1;
				wasFixed = true;
			}
		});
		if(wasFixed){
			this.model.set('rules',rules, { silent: true });
		}
		let model = this.model;
		return new UiBlockView({
			model: this.model,
			header: 'правила',
			content: RulesItemsView,
			contentOptions:{
				collection: model.entity('rules'),
				childViewOptions:{
					mainAction:'view:rule'
				}
			},
			actions:['create:rule'],
		});

	},
	getModelView(){
		let feed = this.model.collection.parent;
		return new UiBlockView({
			model: this.model,
			content: DisplaySchema,
			contentOptions:{
				feed,
				model: this.model, 
				propertiesToShow: [
					'name', 'realtiesListId', 'offersListId', 
					{ 
						name: 'descriptionTemplate',
						edit: { feed },
					}, 
					'disabled'
				],
			}
		});
	},
});
