import _ from 'underscore';
import { CollectionView, View } from 'base';
import { ListItemView, ModelCoreView, CardItemView, AtomTextView } from 'components/views';

import { UiBlockView } from 'components/views';
import { switcherInverse } from 'controls/boolean-switch';
import './offers-cards.less';
export { ApiSelectOffers } from '../selectOffers';


function getRealtyEditUrl(id) {
	let url = 'http://adm.nrg.plus/rlt/buildings/' + id;
	url = `https://app2.nrg.plus/s/rg/actualization/realties-list/${id}/edit`;
	return url;
}

function link(url, text) {
	return `<a href="${url}" target="_blank" class="realty-name-link">${text}</a>`;
}

function realtylink(id, name) {
	const url = '/rlts/realties/' + id;
	return link(url, name);
}

function badgesHtml(...args) {
	return args.map((arg, indx) => `<span class="badge index-${indx}">${arg}</span>`).join('')
}
const BaseOfferItemView = ModelCoreView.extend({
	topText: m => badgesHtml(m.get('id'), m.display('operation'), m.display('market'), m.display('status')),
		//`${m.get('id')} ${m.display('operation')} ${m.display('market')} ${m.display('status')}`,
	text: m => m.display('object-name'),
	smallText: (m,v) =>  `<span class="small-realty-name" title="${m.display('object.realty.address.fullAddress')}">${m.display('object.realty.name')}</span>`,
	_small(){
		// let m = this.model;
		// let landingUrl = m.get('idString') + '.pvt.ru';
		
		// let rgUrl = getRealtyEditUrl(m.get('id'));
		// let landing = `<a href="${landingUrl}" class="spaced-link" data-applink="false" target="_blank">${landingUrl}</a>`;
		// let rg = `<a href="${rgUrl}"  class="spaced-link" data-applink="false" target="_blank">редактировать в nrg</a>`;

		// return [m.display('address.gd'), landing, rg].join('');
	}
});

/*
export const RealtyThumbnail = View.extend({
	tagName: 'img',
	getThumbnail() {
		const thumb = this.getOption('thumbnail');
		if (thumb) {
			return thumb;
		}
		if (this.model) {
			return this.model.get('thumbnail');
		}
	},
	getThumbnailUrl(){
		let thumb = this.getThumbnail();
		if(!thumb) return;
		let size = 88;
		let sizeW = size;
		let sizeH = size;
		if(thumb.origin === 'nrg'){
			let url = `https://media2.nrg.plus/svc/rg/${thumb.hash}/w${sizeW}-h${sizeH}-c-rbc/jpg`;
			return url;
		}
	},
	onBeforeRender(){
		let attrs = {
			src: this.getThumbnailUrl()
		};
		this._setAttributes(attrs);
	}
});
*/

export const OfferItemView = ListItemView.extend({
	className: 'offer-item',
	cssClassModifiers: [
		m => m.get('operation'),
		m => m.get('market'),
		m => m.get('status')
	],
	//optionsButton: true,
	leftItems:[
		//model => new RealtyThumbnail({ model })
	],
	modelView: BaseOfferItemView,
	selectButton: (m,v) => v.isSelectable(),
	selectOnTheRight: true,
});


// const UrlView = AtomTextView.extend({
// 	className: 'spaced-link',
// 	tagName: 'a',
// 	text: (m,v) => v.getOption('label'),
// 	initialize(){
// 		this._setAttributes({
// 			href: this.getOption('url'),
// 			'data-noroute': true,
// 			'target': '_blank'
// 		});
// 	},
// });


const ListsView = View.extend({
	className: 'in-lists',
	cssClassModifiers: [
		m => (m.get('offerLists') || []).length ? 'yes' : 'no'
	],
	template: _.template('в списках: <%= lists %>'),
	templateContext() {		
		let lists = _.pluck(this.model.get('offerLists') || [], 'name').map(name => `<span class="list-name">${name}</span>`).join('; ');
		!lists && (lists = '<span class="no-lists">нет</span>');
		return {
			lists
		};
	}
});


export const OfferItemCard = CardItemView.extend({
	className: 'offer-card',
	modelView: OfferItemView,
	modelEvents: {
		'refresh'() {
			console.warn('REFRESH HAPPENS');
			this.render();
		}
	},
	modelViewOptions(){
		return {
			selectButton: this.isSelectable(),
		};
	},
	lines: () => ([
		v => new ListItemView({ 
			model: v.model, 
			modelView: ListsView, 
			//modelViewOptions: { label: v.model.getLandingUrl(), url: v.model.getLandingUrl() } 
		}),		
		/*
		v => new ListItemView({ 
			model: v.model, 
			modelView: UrlView, 
			modelViewOptions: { label: v.model.getLandingUrl(), url: v.model.getLandingUrl() } 
		}),
		v => new ListItemView({ 
			model: v.model, 
			modelView: UrlView, 
			modelViewOptions: { label: 'редактировать в nrg', url: getRealtyEditUrl(v.model.id), oldurl: v.model.getNRGUrl() } 
		}),
		*/
	]),
});

export const OffersView = CollectionView.extend({
	childView: OfferItemView
});

