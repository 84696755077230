import { CollectionView } from 'base';
import { ListItemView, ModelCoreView } from 'components/views';
import ApiSelect from 'controls/select-api';
import { defineControl, SelectControl } from 'bbmn-controls';

import { UiBlockView } from 'components/views';
import { switcherInverse } from 'controls/boolean-switch';
import { OfferLists } from './models';
import { busViews } from 'bus';


const BaseOfferListItemView = ModelCoreView.extend({
	topText: m => m.display('type'),
	text: m => m.get('name'),
	bottomText: m => m.get('count') || 'пустой'
});

export const OfferListItemView = ListItemView.extend({
	optionsButton: true,
	modelView: BaseOfferListItemView,
});
busViews.class('OfferListItemView', OfferListItemView);

export const OfferListsView = CollectionView.extend({
	childView: OfferListItemView
});


// import { isCollectionClass } from 'bbmn-utils';

// import { UiBlockView } from 'components/views';
// import { switcherInverse } from 'controls/boolean-switch';
// import { busViews } from 'bus';


const ApiSelectOfferList = ApiSelect.extend({
	getSource(){
		return new OfferLists();
	}
});
defineControl('select:api:offerlist', ApiSelectOfferList);