import '../api-config';

import { Collection, ManagedCollection } from 'base';
import { Model } from 'base';
import { CardModel, ActionModel } from 'mod/model-mixins';
import { ModelSchemas } from 'bbmn-components';
import { enums, enumsApi } from 'bbmn-utils';
import { paths } from 'helpers';

export const priceApi = {
	NDS_KOEF: 1.2,
	rent(operation) {
		return operation === 'rent';
	},
	nds(taxType = '') {
		return taxType.indexOf('nds') > -1 ? this.NDS_KOEF : 1;
	},
	boma(operation, boma = 1) {
		return this.rent(operation) ? boma : 1;
	},
	perMeter(baseMeterPrice, operation, taxType = '') {
		const nds = this.nds(taxType);
		const includes = [];
		if (nds > 1) {
			includes.push('ндс');
		}
		const full = baseMeterPrice * nds;
		return {
			raw: baseMeterPrice,
			rawText: this.displayNum(baseMeterPrice),
			full,
			fullText: this.displayNum(full),
			includes,
			postfix: 'за м<sup>2</sup>' + (this.rent(operation) ? ' в год' : '')
		}
	},
	perSquare(square, baseMeterPrice, operation, taxType, boma) {
		// const meter = this.perMeter(baseMeterPrice, taxType);
		const isRent = this.rent(operation);
		let raw = square * baseMeterPrice;
		if (isRent) {
			raw = raw / 12;
		}
		const nds = this.nds(taxType);
		boma = this.boma(operation, boma);
		const full = raw * boma * nds;

		const includes = [];
		if (nds > 1) {
			includes.push('ндс');
		}
		if (boma > 1) {
			includes.push('кор. коэф.');
		}
		// console.warn({
		// 	raw,
		// 	nds,
		// 	boma,
		// 	isRent,
		// })
		return {
			raw,
			rawText: this.displayNum(raw),
			full,
			fullText: this.displayNum(full),			
			postfix: (this.rent(operation) ? 'в месяц' : ''),
			includes
		}
	},
	displayPerMeter(baseMeterPrice, operation, taxType) {
		const info = this.perMeter(baseMeterPrice, operation, taxType);
		const includes = info.includes.length ? 'включая ' + info.includes.join(', ') : '';
		return `<span class="value" title="${includes}">${info.fullText}</span><small class="value-postfix">р. ${info.postfix}</smal>`
	},
	displayPerSquare(square, baseMeterPrice, operation, taxType, boma) {
		const info = this.perSquare(square, baseMeterPrice, taxType, operation, boma);
		const includes = info.includes.length ? 'включая ' + info.includes.join(', ') : '';
		return `<span class="value" title="${includes}">${info.fullText}</span><small class="value-postfix">р. ${info.postfix}</smal>`
	},
	displayNum(num) {
		return num.toLocaleString('ru-RU');
	}
}


export const Offer = ActionModel.extend({
	getShortHumanId(){
		let sqr = (this.getSquare() || '&ndash;') + 'м<sup>2</sup>';
		let flr = 'на ' + (this.get('object.info.floor') || '&ndash;') + ' этаже';
		return sqr + ' ' + flr;
	},
	getSquare(){
		return this.get('object.info.squareOffer');
	},
	getPurposesNames(){
		return enums.get('realtyPurposes', this.get('object.info.purposes'));
	},
	getPriceMeter(){
		const raw = this.get('forCustomer.priceMeter');
		const nds = this.getNdsKoef();
		
		return {
			value: raw,
			fullValue: raw * nds,
			label:'за м<sup>2</sup>'
		};
	},
	getPriceSquare(){
		const raw = this.get('forCustomer.priceMeter');
		const nds = this.getNdsKoef();
		const rent = this.isRent();
		let boma = rent ? (this.get('forCustomer.boma') || 1) : 1;
		let square = this.get('object.info.squareOffer') || 0;
		let rawSquarePrice = (raw * square);
		if (rent) {
			rawSquarePrice = rawSquarePrice/12;
		}
		const full = rawSquarePrice * nds * boma;
		return {
			value: rawSquarePrice,
			fullValue: full,
			label: this.isRent() ? 'в месяц' : 'за всё'
		};
	},
	getNdsKoef() {
		if ((this.get('forCustomer.taxType') || '').indexOf('nds') > -1) {
			return 1.2;
		}
		return 1;
	},
	isRent(){
		return this.get('operation') == 'rent';
	},
	getPrices(){
		let primary, secondary;
		if (this.isRent()) {
			primary = this.getPriceMeter();
			secondary = this.getPriceSquare();
		} else {
			secondary = this.getPriceMeter();
			primary = this.getPriceSquare();
		}
		return { primary, secondary };
	},

	
});


export const Offers = Collection.extend({
	model: Offer,
});



export const RealtyOfferModel = Model.extend({
	realtyEntityType: 'offer',
	getContactsInfo() {
		const ownerId = this.collection.object.get('ownerId');
		const { migrateContact = {} } = this.attributes;
		const { contactId, contactValueId } = migrateContact;
		return { ownerId, contactId, contactValueId }
	},
});

function getObjectAttributes(offerModel) {
	let obj = offerModel.get('object');
	if (obj) { return obj; }
	let objModel = offerModel.collection.object;
	if (objModel) {
		return objModel.attributes;
	}
	console.warn('object missing in offer');
	return {};
}

function getFloorText(floor, features = '') {
	let isSocle = enumsApi.hasFlag(features, 'socleFloor');
	floor = floor || 0;

	if ((floor === 0 || floor === -1) && isSocle) {
		return enums.get('realtyObjectInfoFeatures', 'socleFloor');
	} else if (floor === 0) {
		return 'Подвал'
	}

	if (floor > 0 && enumsApi.hasFlag(features, 'mansard')) {
		return enums.get('realtyObjectInfoFeatures', 'mansard');
	}

	return floor + '<small>Эт.</small>';

}

function getPurposeText(purpose) {
	if (!purpose || purpose === 'none') {
		return;
	}
	return enums.get('realtyPurposes', purpose);
}

ModelSchemas.initialize(RealtyOfferModel, {
	status: {
		value: {
			type: 'enum',
			sourceValues: () => enums.get('realtyOfferStatuses'),
			control: 'select:inline',
		},
		display: {
			label: 'статус публикации',
		}
	},
	operation: {
		value: {
			type: 'enum',
			sourceValues: () => enums.get('realtyOperations'),
			control: 'select:inline',
		},
		display: {
			label: 'операция',
		}
	},
	market: {
		value: {
			type: 'enum',
			sourceValues: () => enums.get('realtyMarkets'),
			control: 'select:inline',
		},
		display: {
			label: 'рынок',
		}
	},
	priceSquare: {
		display: {
			transform(v, options) {
				const taxType = this.get('forCustomer.taxType');
				const boma = this.get('forCustomer.taxType');
				const operation = this.get('operation');
				const object = this.collection.object;
				const square = object.get('info.squareOffer');
				const perMeter = this.get('forCustomer.priceMeter');
				return priceApi.displayPerSquare(square, perMeter, operation, taxType, boma);
			}
		}
	},
	priceMeter: {
		display: {
			transform(v, options) {
				const taxType = this.get('forCustomer.taxType');
				const boma = this.get('forCustomer.taxType');
				const operation = this.get('operation');
				const object = this.collection.object;
				const square = object.get('info.squareOffer');
				const perMeter = this.get('forCustomer.priceMeter');
				return priceApi.displayPerMeter(perMeter, operation, taxType);
			}
		}
	},

	'object-name': {
		value : {

		},
		display: {
			transform(v, options) {
				let obj = getObjectAttributes(this);
				let info = obj?.info || {};
				let { squareOffer, features, currentPurpose, floor } = info;
				let floorText = getFloorText(floor, features);
				let area = squareOffer || 0;
				let purpose = getPurposeText(currentPurpose) || '';
				let name = `${floorText} ${area}<small>м<sup>2</sup></small> ${purpose}`
				return name;
			}
		}
	}


	// disabled: {
	// 	value:{
	// 		type:'boolean',
	// 		control: 'select:inline',
	// 		sourceValues: ['Работает','Отключен']
	// 	},
	// 	display:{
	// 		label:'состояние',
	// 	}
	// },
	// applyGrouping: {
	// 	value:{
	// 		type:'boolean',
	// 		control: 'select:inline',
	// 		sourceValues: ['Без группировки','Объявления группируются']
	// 	},
	// 	display:{
	// 		label: 'Группировка объявлений'
	// 	}
	// },
	// weekendAsUsual: {
	// 	value:{
	// 		type:'boolean',
	// 		control: 'select:inline',
	// 		sourceValues: ['Только продвинутые объявления','Как в рабочие дни']
	// 	},
	// 	display:{
	// 		label: 'Выгрузка на выходных'
	// 	}
	// },
	// takeOnlyPromoted: {
	// 	value:{
	// 		type:'boolean',
	// 		control: 'select:inline',
	// 		sourceValues: ['Все подряд','Только продвинутые']
	// 	},
	// 	display:{
	// 		label: 'Выгружаются объявления'
	// 	}
	// },
	// maximumAd: {
	// 	value: {
	// 		type:'number',
	// 	},
	// 	display:{
	// 		label: 'объявлений не более',
	// 		ifEmpty: 'без ограничений'
	// 	}
	// },
	// url:{
	// 	edit: false,
	// 	display:{
	// 		label:'ссылка',
	// 		transform(){
	// 			let url = this.getFeedUrl({ publish: true });
	// 			return `<a href="${url}">${this.id}</a>`;
	// 		}
	// 	}
	// },
	// public: {
	// 	value:{
	// 		type:'boolean',
	// 		control: 'select:inline',
	// 		sourceValues: ['нет','да']
	// 	},
	// 	display:{
	// 		label: 'опубликован'
	// 	}
	// },
	// lastAccess: {
	// 	edit: false,
	// 	display: {
	// 		label: 'дата последнего скачивания',
	// 		transform(v){
	// 			if (v == null) {
	// 				return 'нет данных';
	// 			}
	// 			let d = new Date(v);
	// 			return d.toLocaleString('ru');
	// 		}
	// 	}
	// },
	// 'descriptionTemplate': {
	// 	value:{
	// 		type: 'text',
	// 		control: 'advert:template'
	// 	},
	// 	display: {
	// 		label: 'основной шаблон описания',
	// 		ifEmpty: '&ndash;',
	// 		ifNotEmpty: 'установлен собственный шаблон',
	// 	}
	// },
	/*'settings.phones.default': {
		value:{
			control: 'phone',
		},
		display:{
			label: 'основной телефон',
			ifEmpty: 'не установлен, будет подставлен телефон по умолчанию'
		}
	},
	'settings.phones.promotionsDefault': {
		value:{
			control: 'phone',
		},
		display:{
			label: 'телефон для продвижений',
			ifEmpty: 'будет использован основной телефон'
		}
	}*/
}, {
	// notifies: createSchemaNotifies({ name: 'предложение', generate: true })
});


export const BackendOffers = ManagedCollection.extend({
	model: RealtyOfferModel,
	url: paths.api('realties:offers'),
	concurrentFetch:'allowed',
	fetchMethodName: 'POST',
});