import { CollectionView } from 'base';
import { ListItemView, ModelCoreView } from 'components/views';
import ApiSelect from 'controls/select-api';
import { defineControl, SelectControl } from 'bbmn-controls';
import { isCollectionClass } from 'bbmn-utils';

import { UiBlockView } from 'components/views';
import { switcherInverse } from 'controls/boolean-switch';
import { RealtyLists } from './models';
import { busViews } from 'bus';

const BaseRealtyListItemView = ModelCoreView.extend({
	topText: m => m.display('type'),
	text: m => m.get('name'),
	bottomText: m => m.get('count') || 'пустой'
});

export const RealtyListItemView = ListItemView.extend({
	className:'realtylist-item',
	optionsButton: true,
	modelView: BaseRealtyListItemView,
});

busViews.class('RealtyListItemView', RealtyListItemView);

export const RealtyListsView = CollectionView.extend({
	childView: RealtyListItemView
});


const ApiSelectRealtyList = ApiSelect.extend({
	getSource(){
		return new RealtyLists();
	}
});
defineControl('select:api:realtylist', ApiSelectRealtyList);
